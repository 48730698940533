@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Nunito Sans", sans-serif;
}

.mulish-thin {
  font-family: "Mulish", sans-serif;
  /* font-optical-sizing: auto;
  font-weight: 100; */
  font-style: normal;
}

.mulish-thin {
  font-family: "Mulish", sans-serif;
  /* font-optical-sizing: auto;
  font-weight: 100; */
  font-style: normal;
}

.oregano-regular {
  font-family: "Oregano", cursive;
  font-weight: 400;
  font-style: normal;
}

.oregano-regular-italic {
  font-family: "Oregano", cursive;
  font-weight: 400;
  font-style: italic;
}

/* ===== Scrollbar Customization CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #13006e #08012c;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #140c3a;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: #140c3a;
  border-radius: 4px;
  border: none;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #140c3a;
}
